import styled from 'styled-components';
import { device, theme } from '@components/ui/theme';
import { Icon } from '@components/ui/icons/icon';

export const Title = styled.h1`
  margin-top: 5px;
  font-weight: 500;
  font-size: 34px;
  line-height: 42px;
`;

export const Subtitle = styled.p`
  color: ${theme.colors.greyPrimary};
  font-size: 17px;
  line-height: 20px;

  a {
    color: ${theme.colors.primary};
    border-bottom: 1px solid ${theme.colors.primary};
  }
`;

export const StyledWrongPage = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-row-gap: 30px;
  background: #f9f7fa;
  height: 100%;
  min-height: 516px;

  svg {
    width: 60px;
    height: auto;
    color: ${theme.colors.primary};
  }

  @media ${device.tabletM} {
    grid-row-gap: 20px;

    ${Icon} svg {
      width: 50px;
    }

    ${Title} {
      font-size: 30px;
      line-height: 36px;
    }
  }

  @media ${device.tabletS} {
    min-height: 416px;
  }

  @media (max-width: 690px) {
    ${Title}, ${Subtitle} {
      width: 287px;
      text-align: center;
    }

    ${Title} {
      font-size: 20px;
      line-height: 24px;
    }

    ${Subtitle} {
      margin-top: -4px;
      font-size: 14px;
      line-height: 19px;
    }
  }

  @media (max-width: 340px) {
    min-height: 384px;
  }
`;
