import Layout from "@components/layout/layout";
import React from "react";
import { WrongPage } from "@components/wrong-page/wrong-page";
import { NextPage } from "next";

const Page404: NextPage = () => {
  return (
    <Layout showHowItWorks={false} noFooterMargin noHeaderGreyZone>
      <WrongPage />
    </Layout>
  );
};

export default Page404;
