import React from 'react';
import * as S from './wrong-page.style';
import Link from 'next/link';
import { Icon } from '@components/ui/icons/icon';

export const WrongPage = () => {
  return (
    <S.StyledWrongPage>
      <Icon icon="wrong" />
      <S.Title>К сожалению, указанная страница не найдена.</S.Title>
      <S.Subtitle>
        Воспользуйтесь поиском или перейдите на{' '}
        <Link legacyBehavior prefetch={false} href="/">
          <a href="#">главную страницу</a>
        </Link>
      </S.Subtitle>
    </S.StyledWrongPage>
  );
};
